<template>
  <div class="content-wrapper">
    <PageHeader
      screen-name="Ver vacinados"
      :link-items="linkItems"
    />
    <b-modal
      id="add-new-item"
      v-model="showModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-header
      hide-footer
      centered
    >
      <div
        v-if="showModal"
        class="d-flex justify-content-center my-3"
      >
        <div
          class="spinner-border text-custom-blue"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <h2 class="text-center">
        Estamos processando
      </h2>

    </b-modal>
    <div class="card pt-2">
      <main>
        <validation-observer ref="selectRules">
          <b-row class="px-2 pt-2">
            <b-col
              v-if="this.companyUser || this.nationalUser"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group
                label="Grupo"
                label-for="select-grupo"
                label-class="font_size_label"
              >
                <v-select
                  id="select-grupo"
                  v-model="search.grupo"
                  variant="custom"
                  item-text="descricao"
                  item-value="id_grupo"
                  label="descricao"
                  placeholder="Selecione um grupo"
                  :options="grupoSelect"
                >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              v-if="this.companyUser || this.nationalUser"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group
                label="UF | DR Operador"
                label-for="select-operador"
                label-class="font_size_label"
              >
                <v-select
                  id="select-operador"
                  v-model="search.operador"
                  variant="custom"
                  item-text="uf"
                  item-value="id_departamento"
                  label="uf"
                  placeholder="Selecione uma UF"
                  :options="operadorSelect"
                >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              lg="4"
              md="12"
              sm="12"
              :class="{'pt-3': !this.companyUser && !this.nationalUser}"
            >
              <b-form-group
                label="Empresa"
                label-for="select-empresa"
                label-class="font_size_label"
              >
                <v-select
                  id="select-empresa"
                  v-model="search.empresa"
                  variant="custom"
                  item-text="descricao"
                  item-value="id_empresa"
                  label="nome_empresa"
                  placeholder="Selecione uma empresa"
                  :values="empresaSelect"
                  :options="empresaSelect"
                  :loading="loading.company"
                >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                  <template #option="{nome_empresa, documento_formatado}">
                    <strong>{{ nome_empresa }}</strong><br>
                    <span>{{ documento_formatado }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="12"
              :lg="this.companyUser || this.nationalUser ? '12' : '8'"
            >
              <b-badge class="w-100 badge-light-blue">
                Período de vacinação
              </b-badge>
              <b-row class="pt-1">
                <b-col
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <b-form-group
                    label="Data Início"
                    label-for="inicio-input"
                    label-class="font_size_label"
                  >
                    <validation-provider
                      name="Data Início"
                    >
                      <b-input-group>
                        <b-form-input
                          id="inicio-input"
                          v-model="input.inicio"
                          v-mask="'##/##/####'"
                          class="form-control"
                          placeholder="00/00/0000"
                          autocomplete="off"
                          @input="setInicioInput"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="search.inicio"
                            class="form-control d-flex align-items-center"
                            locale="pt-BR"
                            button-only
                            right
                            :hide-header="true"
                            label-help="Selecione a data de inicio"
                            button-variant="custom-blue"
                            clearable
                            @input="setInicioPicker"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <b-form-group
                    label="Data Final"
                    label-for="final-input"
                    label-class="font_size_label"
                  >
                    <validation-provider
                      name="Data Final"
                    >
                      <b-input-group>
                        <b-form-input
                          id="final-input"
                          v-model="input.final"
                          v-mask="'##/##/####'"
                          class="form-control"
                          placeholder="00/00/0000"
                          autocomplete="off"
                          @input="setFinalInput"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="search.final"
                            class="form-control d-flex align-items-center"
                            locale="pt-BR"
                            button-only
                            right
                            :hide-header="true"
                            label-help="Selecione a data final"
                            button-variant="custom-blue"
                            clearable
                            @input="setFinalPicker"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
              lg="12"
              class="d-flex justify-content-end"
            >
              <b-button
                variant="link"
                class="text-uppercase text-decoration-underline"
                @click="toggleAdvancedSearch"
              />
            </b-col>
          </b-row>
          <transition name="fade">
            <div :class="{fadeIn: isAdvancedSearchActive}">
              <b-row class="px-2">
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                >
                  <b-form-group
                    label="Nome"
                    label-for="nome-pessoa-input"
                    label-class="font_size_label"
                  >
                    <b-form-input
                      id="nome-pessoa-input"
                      v-model="search.nome"
                      v-mask="('X'.repeat(255))"
                      placeholder="Nome da pessoa"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                >
                  <b-form-group
                    label="CPF"
                    label-for="cpf-pessoa-input"
                    label-class="font_size_label"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="cpf"
                      rules="min:14"
                    >
                      <b-form-input
                        id="cpf-pessoa-input"
                        v-model="search.cpf"
                        v-mask="'###.###.###-##'"
                        placeholder="000.000.000-00"
                        max-length="14"
                      />

                      <small
                        v-if="errors[0]"
                        class="text-danger"
                      >
                        Este campo deve conter o CPF completo
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                >
                  <b-form-group
                    label="Matrícula"
                    label-for="matricula-input"
                    label-class="font_size_label"
                  >
                    <validation-provider
                      name="matricula"
                    >
                      <b-form-input
                        id="matricula-input"
                        v-model="search.matricula"
                        placeholder="Identificação interna"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                >
                  <b-form-group
                    label="Setor"
                    label-for="setor-input"
                    label-class="font_size_label"
                  >
                    <validation-provider
                      name="setor"
                    >
                      <b-form-input
                        id="setor-input"
                        v-model="search.setor"
                        placeholder="Área, setor ou departamento"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                  class="pt-2"
                >
                  <b-form-group
                    label="Categoria"
                    label-for="perfil-input"
                    label-class="font_size_label"
                  >
                    <v-select
                      id="select-perfil"
                      v-model="search.perfil"
                      variant="custom"
                      placeholder="Selecione uma categoria"
                      :values="perfilSelect"
                      :options="perfilSelect"
                    >
                      <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                  class="pt-2"
                >
                  <b-form-group
                    label="Vacinador"
                    label-for="vacinador-input"
                    label-class="font_size_label"
                  >
                    <v-select
                      id="select-vacinador"
                      v-model="search.vacinador"
                      variant="custom"
                      placeholder="Selecione um vacinador"
                      :values="vacinadorSelect"
                      :options="vacinadorSelect"
                    >
                      <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                  class="pt-2"
                >
                  <b-form-group
                    label="Campanha"
                    label-for="campanha-input"
                    label-class="font_size_label"
                  >
                    <v-select
                      id="select-campanha"
                      v-model="search.campanha"
                      variant="custom"
                      label="descricao"
                      placeholder="Selecione uma campanha"
                      :values="campanhaSelect"
                      :options="campanhaSelect"
                      :loading="loading.campaign"
                    >
                      <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                  class="pt-2"
                >
                  <b-form-group
                    label="Unidade Operacional"
                    label-for="unidade-operacional-input"
                    label-class="font_size_label"
                  >
                    <v-select
                      id="unidade-operacional-input"
                      v-model="search.unidade"
                      :options="unidadeSelect"
                      label="descricao_unidade"
                      placeholder="Selecione uma unidade"
                      :clearable="true"
                      :loading="loading.unity"
                    >
                      <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="pt-2 px-2">
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                >
                  <b-form-group
                    label="Lote"
                    label-for="lote-input"
                    label-class="font_size_label"
                  >
                    <v-select
                      id="lote-input"
                      v-model="search.lote"
                      :options="loteSelect"
                      label="numero_lote"
                      placeholder="Selecione o lote"
                      :clearable="true"
                      :loading="loading.lote"
                    >
                      <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                >
                  <b-form-group
                    label="Vacina"
                    label-for="vacina-input"
                    label-class="font_size_label"
                  >
                    <v-select
                      id="vacina-input"
                      v-model="search.vacina"
                      :options="vacinaSelect"
                      label="nome_tecnico_vacina"
                      placeholder="Selecione uma Vacina"
                      :clearable="true"
                      :loading="loading.vacina"
                    >
                      <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </transition>
          <b-row class="justify-content-center py-3">
            <b-button
              variant="primary-button"
              class="mr-2"
              @click="validateAndFilterList"
            >
              <feather-icon
                icon="SearchIcon"
                class="mr-50"
              />
              <span class="align-middle">Pesquisar</span>
            </b-button>

            <b-button
              type="reset"
              class="cor_botao"
              variant="outline-primary"
              @click="cleanSearch"
            >
              <feather-icon
                icon="XIcon"
                class="mr-50"
              />
              <span class="align-middle">Limpar</span>
            </b-button>
          </b-row>
        </validation-observer>
        <hr class="mx-1 mb-1">
        <b-row class="my-2 px-2">
          <b-col
            lg="6"
            md="6"
            sm="6"
          >
            <div class="d-flex align-items-center">
              <span
                class="mr-1"
                for="tableSize"
              >Mostrar</span>
              <v-select
                id="tableSize"
                v-model="paginationData.defaultSize"
                :options="table.optionsSize"
                :clearable="false"
                @input="filterList()"
              >
                <span slot="no-options">Nenhuma opção selecionável.</span>
              </v-select>
            </div>
          </b-col>
          <b-col
            lg="6"
            md="6"
            sm="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="outline-primary"
                class="cor_botao"
                :disabled="activeButtonSheet"
                @click="downloadSpreadsheet()"
              >
                <feather-icon
                  id="download-import"
                  v-b-tooltip.hover.bottomleft="{ variant: 'light' }"
                  role="button"
                  class="text-custom-blue"
                  icon="DownloadIcon"
                  size="18"
                  :title="'Exportação de planilha de vacinados'"
                />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </main>

      <section
        v-if="!loadingData"
        class="list-vacinacao"
      >
        <b-table
          id="tableVacinacao"
          ref="tableVacinacao"
          responsive
          :sticky-column="true"
          :busy.sync="filtering"
          :fields="table.fields"
          :items="table.items"
          no-local-sorting
          :no-sort-reset="true"
          @sort-changed="ordenarColuna"
        >
          <template #head(data_aplicacao)>
            <span class="d-block">APLICAÇÃO</span>
            <span class="d-block">VACINADOR</span>
          </template>
          <template #head(setor)>
            <span class="d-block">SETOR</span>
            <span class="d-block">MATRÍCULA</span>
          </template>

          <template #cell(pessoa_fisica.nome)="row">
            <strong class="d-block comprimento_maximo_celulas">{{ row.item.pessoa_fisica.nome }}</strong>
            <small class="d-block">{{ row.item.pessoa_fisica.cpf | VMask('###.###.###-##') }}</small>
          </template>

          <template #cell(data_aplicacao)="row">
            <span class="d-block">{{ row.item.data_aplicacao }}</span>
            <span class="d-block">{{ row.item.usuario_aplicador.pessoa_fisica.nome }}</span>
          </template>
          <template #cell(setor)="row">
            <span class="d-block">{{ row.item.setor }}</span>
            <span class="d-block">{{ row.item.matricula }}</span>
            <span
              v-if="!row.item.matricula && !row.item.setor"
              class="d-block"
            >-</span>
          </template>
          <template #cell(numero_lote)="row">
            <span class="d-block">{{ row.item.lote_unidade.lote.numero_lote }}</span>
            <span class="d-block">{{ row.item.lote_unidade.lote.fabricante.descricao }}</span>
          </template>
        </b-table>
        <div class="pb-2 px-1">
          <span
            v-if="without"
            class="ml-1"
          >Nenhum colaborador encontrado</span>
          <b-col
            sm="12"
            class="mt-2"
          >
            <CustomPagination
              :paginacao="paginationData"
              @page-cliked="updateCurrentPage"
            />
          </b-col>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  VBTooltip,
  BSpinner,
  BCard,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BBadge
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment'
import { min } from '@validations'
import vSelect from 'vue-select'
import {
  getNationalPurchaseGroups,
  getDepartmentsOperatorGroups,
} from '@/views/custom-pages/adesao-nacional/requests'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import InfograficoEstoqueCard from '@/views/components/custom/cards/InfograficoEstoqueCard.vue'
import InfoCard from '@/views/components/custom/cards/InfoCard.vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import TableSwitch from '@/views/components/custom/table-switch/TableSwitch.vue'
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue'
import { getUserData } from '@/auth/utils'
import { actions, subjects } from '@/libs/acl/rules'

export default {
  title: 'Ver vacinados',

  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    TableSwitch,
    BCard,
    vSelect,
    CustomPagination,
    PageHeader,
    TimedAlert,
    VuePerfectScrollbar,
    BSpinner,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    InfograficoEstoqueCard,
    InfoCard,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    getNationalPurchaseGroups,
    getDepartmentsOperatorGroups,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      min,
      linkItems: [
        {
          name: 'Vacinação',
          active: true,
        },
        {
          name: 'Ver vacinados',
          active: true,
        },
      ],
      alert: {
        errorMessage: '',
        show: false,
      },
      loadingData: false,
      filtering: false,
      table: {
        fields: [
          {
            key: 'pessoa_fisica.nome', label: 'NOME | CPF', sortable: true, class: 'align-middle mw-200',
          },
          {
            key: 'setor', label: 'SETOR MATRÍCULA', sortable: false, class: 'align-middle mw-50',
          },
          {
            key: 'lote_unidade.lote.vacina.nome_popular',
            label: 'VACINA',
            sortable: false,
            class: 'align-middle mw-100',
          },
          {
            key: 'numero_lote', label: 'LOTE | FABRICANTE', sortable: false, class: 'align-middle mw-50',
          },
          {
            key: 'validade', label: 'VALIDADE', sortable: false, class: 'align-middle mw-50',
          },
          {
            key: 'data_aplicacao', label: 'APLICAÇÃO VACINADOR', sortable: false, class: 'align-middle mw-50',
          },
        ],
        items: [],
        optionsSize: [10, 25, 50, 100],
        columnOrder: 'pessoa_fisica.nome',
        order: 'asc',
      },
      paginationData: {
        currentPage: 1,
        defaultSize: 10,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
      },
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.2,
        wheelPropagation: false,
      },
      isAdvancedSearchActive: false,
      search: {
        unidade: '',
        empresa: '',
        campanha: '',
        vacinador: '',
        perfil: '',
        nome: '',
        cpf: '',
        inicio: '',
        final: '',
        matricula: '',
        setor: '',
        grupo: '',
        operador: '',
        lote: '',
        vacina: '',
      },
      input: {
        inicio: this.getDateRangeInitialValuesWithFormat('DD/MM/YYYY').startDate,
        final: this.getDateRangeInitialValuesWithFormat('DD/MM/YYYY').endDate,
      },
      loading: {
        company: true,
        campaign: true,
        unity: true,
        lote: true,
        vacina: true,
      },
      unidadeSelect: [],
      empresaSelect: [],
      campanhaSelect: [],
      vacinadorSelect: [],
      loteSelect: [],
      vacinaSelect: [],
      perfilSelect: [],
      grupoSelect: [],
      operadorSelect: [],
      parameters: {
        colunaOrdenar: 'pessoa_fisica.nome',
        ordem: 'asc',
        data_inicial: this.getDateRangeInitialValuesWithFormat('YYYY-MM-DD').startDate,
        data_final: this.getDateRangeInitialValuesWithFormat('YYYY-MM-DD').endDate,
      },
      activeButtonSheet: false,
      showModal: false,
      userData: getUserData(),
      companyUser: this.$can(actions.VISUALIZAR, subjects.EMPRESAS_AE),
      nationalUser: this.$can(actions.VISUALIZAR, subjects.ATUACAO_BASE_NACIONAL),
    }
  },
  computed: {
    without() {
      return this.table.items.length == 0
    },
  },
  mounted() {
    this.loadingData = true
    if (this.companyUser || this.nationalUser) {
      this.handleGetGroups()
    }
    this.loadInfo()
  },
  methods: {
    handleGetGroups() {
      this.loadGroup()
      this.loadOperator()
    },
    loadGroup() {
      const params = {
        todos: true,
        colunaNome: 'descricao',
        colunaOrdem: 'asc',
      }
      this.$http.get(this.$api.getAndPostGrupo(), { params }).then(({ data }) => {
        this.grupoSelect = data
      }).catch(() => {
        this.$helpers.modalErroServidorPadrao()
      })
    },
    loadOperator() {
      const params = {
        tipo_departamento: 'Regional',
        dr_operador: !!this.companyUser,
      }
      this.$http.get(this.$api.getDepartmentNational(), { params }).then(({ data }) => {
        data.sort((a, b) => {
          if (a.uf < b.uf) return -1
          if (a.uf > b.uf) return 1
          return 0
        })
        this.operadorSelect = data
      }).catch(() => {
        this.$helpers.modalErroServidorPadrao()
      })
    },
    async loadInfo() {
      this.loadData()

      const parameters = {
        ativo: true,
        paginacao: false,
      }
      const parametersEmpresa = { ...parameters, ...{ uf: this.userData.departamento.uf } }

      const parametersLote = {
        dependencia: false, ativo: false, colunaOrdenar: 'numero_lote', ordem: 'asc',
      }

      await this.$http.get(this.$api.unidade(), { params: parameters }).then(({ data }) => {
        this.unidadeSelect = data
        this.loading.unity = false
      })
      await this.$http.get(this.$api.saveAndGetEmpresa(), { params: parametersEmpresa }).then(({ data }) => {
        this.empresaSelect = data
        this.loading.company = false
      })
      await this.$http.get(this.$api.campanha(), { params: parameters }).then(({ data }) => {
        this.campanhaSelect = data.map(option => ({
          descricao: option.campanha_descricao,
          id_campanha: option.id_campanha,
        }))
        this.loading.campaign = false
      })
      await this.$http.get(this.$api.vacinadores()).then(({ data }) => {
        this.vacinadorSelect = data.map(option => ({
          value: option.id_usuario,
          label: option.nome,
        }
        ))
      })
      await this.$http.get(this.$api.getEmpresaCategoria()).then(({ data }) => {
        this.perfilSelect = data.map(option => ({
          value: option.id_categoria,
          label: option.descricao,
        }))
      })
      await this.$http.get(this.$api.lote(), { params: parametersLote }).then(({ data }) => {
        const lotes = new Set()

        this.loteSelect = data.filter(lote => {
          const duplicatedLote = lotes.has(lote.id_lote)
          lotes.add(lote.id_lote)
          return !duplicatedLote
        })

        this.loading.lote = false
      })
      await this.$http.get(this.$api.saveAndGetVacina(), { params: parameters }).then(({ data }) => {
        this.vacinaSelect = data.data
        this.loading.vacina = false
      })
    },
    async loadData() {
      await this.$http.get(this.$api.vacinacao(), {
        params: {
          pagina: this.paginationData.currentPage,
          tamanho: this.paginationData.defaultSize,
          ...this.parameters,
        },
      }).then(({ data }) => {
        this.table.items = data.data.map(item => ({
          ...item,
          validade: moment(item.lote_unidade.lote.validade).format('DD/MM/YYYY'),
          data_aplicacao: moment.utc(item.data_aplicacao).format('DD/MM/YYYY'),
        }))
        this.startPagination(data)
      })
      this.filtering = false
      this.loadingData = false
    },
    startPagination(data) {
      this.paginationData.currentPage = data.current_page
      this.paginationData.totalLines = data.total
      this.paginationData.fromLine = data.from
      this.paginationData.toLine = data.to
    },
    updateCurrentPage(page) {
      this.paginationData.currentPage = page
      this.filtering = true
      this.loadData()
    },
    async filterList() {
      const parameters = {
        id_unidade: this.search.unidade ? this.search.unidade.id_unidade : '',
        id_empresa: this.search.empresa ? this.search.empresa.id_empresa : '',
        id_campanha: this.search.campanha ? this.search.campanha.id_campanha : '',
        id_usuario_vacinador: this.search.vacinador ? this.search.vacinador.value : '',
        id_lote: this.search.lote ? this.search.lote.id_lote : '',
        id_vacina: this.search.vacina ? this.search.vacina.id_vacina : '',
        id_perfil: this.search.perfil ? this.search.perfil.value : '',
        data_final: this.search.final,
        data_inicial: this.search.inicio,
        cpf: this.formatCpf(this.search.cpf),
        termoPesquisa: this.search.nome,
        colunaOrdenar: this.table.columnOrder,
        matricula: this.search.matricula,
        setor: this.search.setor,
        ordem: this.table.order,
        id_grupo: this.search.grupo ? this.search.grupo.id_grupo : '',
        id_departamento_operador: this.search.operador ? this.search.operador.id_departamento : '',
      }
      this.parameters = parameters
      this.filtering = true
      this.paginationData.currentPage = 1
      this.loadData()
    },
    cleanSearch() {
      this.search.unidade = ''
      this.search.empresa = ''
      this.search.campanha = ''
      this.search.vacinador = ''
      this.search.perfil = ''
      this.search.nome = ''
      this.search.cpf = ''
      this.search.inicio = ''
      this.search.final = ''
      this.search.matricula = ''
      this.search.setor = ''
      this.search.lote = ''
      this.search.vacina = ''
      this.input.inicio = ''
      this.input.final = ''
      this.search.grupo = ''
      this.search.operador = ''
      this.parameters = {
        colunaOrdenar: this.table.columnOrder,
        ordem: this.table.order,
        data_inicial: '',
        data_final: '',
      }
      this.loadInfo()
      this.filtering = true
    },
    ordenarColuna(sort) {
      this.table.columnOrder = sort.sortBy
      this.table.order = sort.sortDesc ? 'desc' : 'asc'

      this.filtering = true

      this.filterList()
    },
    toggleAdvancedSearch() {
      this.isAdvancedSearchActive = !this.isAdvancedSearchActive
    },
    setInicioInput(value) {
      this.input.inicio = value
      this.search.inicio = value ? moment(this.input.inicio, 'DD/MM/YYYY').format('YYYY-MM-DD') : value
    },
    setInicioPicker(value) {
      this.search.inicio = value
      this.input.inicio = value ? moment(this.search.inicio).format('DD/MM/YYYY') : value
    },
    setFinalInput(value) {
      this.input.final = value
      this.search.final = value ? moment(this.input.final, 'DD/MM/YYYY').format('YYYY-MM-DD') : value
    },
    setFinalPicker(value) {
      this.search.final = value
      this.input.final = value ? moment(this.search.final).format('DD/MM/YYYY') : value
    },
    validateAndFilterList() {
      this.$refs.selectRules.validate().then(success => {
        if (success) {
          this.filterList()
        }
      })
    },
    formatCpf(cpf) {
      return cpf.replace(/\D/g, '')
    },
    async downloadSpreadsheet() {
      this.activeButtonSheet = true
      this.showModal = true
      await this.$http.get(this.$api.vacinacao(), {
        params: {
          ...this.parameters,
        },
      }).then(({ data }) => {
        const items = data.map(item => ({
          nome: item.pessoa_fisica.nome,
          cpf: item.pessoa_fisica.cpf,
          setor: item.setor ? item.setor : '-',
          matricula: item.matricula ? item.matricula : '-',
          vacina: item.lote_unidade.lote.vacina.nome_popular,
          lote: item.lote_unidade.lote.numero_lote,
          fabricante: item.lote_unidade.lote.fabricante.descricao,
          validade: moment(item.lote_unidade.lote.validade).format('DD/MM/YYYY'),
          data_aplicacao: moment.utc(item.data_aplicacao).format('DD/MM/YYYY'),
          aplicador: item.usuario_aplicador.pessoa_fisica.nome,
          grupo: item.grupo,
          uf: item.uf_operador,
          'cnpj/cpf': item.cnpj,
          nome_fantasia: item.nome_fantasia,
          razao_social: item.razao_social,
        }))
        this.$helpers.downloadArchiveXlsx(items, 'planilha_vacinacao.xlsx')
      }).finally(() => {
        this.showModal = false
        this.activeButtonSheet = false
      })
    },
    formatDocument(document) {
      if (document.length === 14) {
        return this.$options.filters.VMask(document, '##.###.###/####-##')
      }
      if (document.length === 11) {
        return this.$options.filters.VMask(document, '###.###.###-##')
      }
      return document
    },

    getDateRangeInitialValuesWithFormat(format = 'YYYY-MM-DD') {
      const endDate = moment(new Date()).format(format)
      const startDate = moment().startOf('year').format(format)

      return { startDate, endDate }
    },
  },
}
</script>

<style>
.font_size_label {
  font-size: 15px;
  margin-top: -5px;
}

.cor_botao {
  color: #2772C0 !important;
  border: 1px solid #2772C0 !important;
}

.comprimento_maximo_celulas {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}

.badge-light-blue {
  background: rgba(39, 114, 192, 0.12);
  color: #5E5873;
  font-size: 1rem;
  font-weight: 400;
  padding: .5rem 0;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  max-height: 0;
}

.fade-leave-active {
  animation: fade .2s ease-in-out reverse;
}

.fade-enter-active {
  animation: fade .2s ease-in-out forwards;
}

@keyframes fade {
  from {
    opacity: 0;
    max-height: 0;
  }

  to {
    opacity: 1;
    max-height: 50vh;
  }
}

#tableSize {
  min-width: 80px;
}
</style>
